/* Header.css */

.navbar-logo {
    width: 190px; /* Adjust size as needed */
  }
  .navbar {
    padding: 1rem 8rem !important;
  }
  .navbar-brand {
    margin: 0 !important;
  }

  .navbar-toggler {
    padding: 0 !important;
    border: 0 !important;
  }
  /* .navbar>.container-sm {
  } */
  .register-now-link {
    background-color: #FFD700; /* Yellow background */
    color: black;
    padding: 7px 10px;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    border: 0;
    box-shadow: 1px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s, color 0.3s;
  }
  .nav-link:hover {
    color: #FBC500 !important;
  }
  
  .register-now-link:hover {
    background-color: white;
    color: black;
    text-decoration: none;
  }

  /* Header.css */

 /* Header.css */

.navbar-collapse {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    width: 250px;
    background-color: white;
    transition: left 0.3s ease-in-out;
    z-index: 1040;
  }
  
  .navbar-collapse.show {
    left: 0;
  }
  
  /* .navbar-toggler {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1050;
  }
   */
  .close-btn {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1050;
    background: none;
    border: none;
    font-size: 1.5rem;
    display: none;
  }
  
  .close-btn.show {
    display: block !important;
  }
  
  .navbar-toggler.hide {
    display: none;
  }
  
  
@media (min-width: 992px) {
    .navbar-collapse {
      position: relative;
      left: 0;
      height: auto;
      width: auto;
      background-color: transparent;
      flex-direction: row;
      justify-content: flex-end;
      display: flex;
    }
  
    .close-btn {
      display: none;
    }
  
    .navbar-toggler {
      display: none;
    }
  }
  
  
  @media (max-width: 991.98px) {
    .navbar .container{
    flex-wrap: nowrap !important;

    }
    .navbar-logo {
      width: 120px; /* Adjust size as needed */
    }
    .navbar-nav {
        padding: 3rem 0rem;
    }
    .navbar {
        padding: 1rem  !important;
      }
    .register-now-link {
      margin-left: 0;
    }
    
    .navbar-toggler {
      order: -1;
    }
  }
  