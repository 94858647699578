.services-grid {
    text-align: center;
    padding: 20px;
  }
  
  .grid-container {
    display: flex;
    justify-content: center;
    /* gap: 20px; */
    margin-top: 20px;
  }
  
  .grid-item {
    position: relative;
    /* width: 200px; */
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .grid-item img {
    /* width: 100%;
    height: 100%; */
    object-fit: cover;
  }
  
  .item-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #d6a842;
    color: white;
    text-align: center;
    padding: 10px 0;
  }