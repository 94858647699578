.services .card-container {
        display: flex;
        justify-content: center;
        /* gap: 20px; */
        margin-top: 20px;
      }
      
      .services      .card {
        background-color: #d6a842;
        padding: 1rem;
        border-radius: 10px;
        height: 320px;
        /* width: 300px; */
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        position: relative;
      }
      
      .services .card h3  {
        margin: 014px 0px 014px 0px;
        color: #000000;
        font-family: "Poppins", Sans-serif !important;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.1em;
    }
    .book-now{
        position: absolute;
        bottom: 22px;
    }
      
      .services .card p {
        font-size: 1em;
        margin-bottom: 20px;
      }
      
      /* .services .card button {
        background-color: #000;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      } */
      
      .services .card button:hover {
        background-color: #fff !important;
        color: #000000 !important;
      }

.services {
    text-align: center;
        padding: 20px;
}

.btn-book-now {
    background-color: black !important; /* Yellow background */
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    border: 0;
    box-shadow: 1px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s, color 0.3s;
}

@media(min-width: 760px) and (max-width: 1180px) {
  .services      .card {
    height: auto;
  }
}