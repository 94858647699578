.banner-section {
    background-image: url('../assets/images/globe.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #0A0B3CFA;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0px 9rem;
    /* text-align: center; */
    position: relative;
  }
  .export {
    display: flex;
    justify-content: center;
  }
  .banner-section::after {
    content: '';
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 50px;
    background: url('../assets/images/f-wave.svg') no-repeat center bottom;
    background-size: cover !important;
  }
  .banner-section h1 {
    color: #FFFFFF;
    font-family: "Chelsea Market", Sans-serif;
    font-size: 35px;
    font-weight: bold;
  }
  @media (max-width: 991.98px) {
  .banner-section::after {
    background: url('../assets/images/wave.svg') no-repeat center bottom;
    bottom: -3px;

  }
    .banner-section {

    padding: 0px ;
    }
  .export {
    display: none !important;
  }
}