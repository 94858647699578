.App {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.export-box {
   padding: 2em 2em 2em 2em; 
}
.export-box .icon {
   padding: 14px 14px 14px 14px; 
}
.footer-bg {
    background-color: #D6A842;
    color: #fff;
}

.about{
    color: #FFFFFF;
    line-height: 30px;
    letter-spacing: -0.6px;
    word-spacing: 4px;
    font-weight: bolder;
}

#about-us h5:first-of-type  {
    color: black;
}
.contact {
        color: #FFFFFF;
        line-height: 30px;
        letter-spacing: -0.6px;
        word-spacing: 4px;
        font-weight: bolder;
}
.contact h5 {
    color: #FFFFFF !important;

}